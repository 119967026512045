import React from 'react'

function ReviewList() {
  return (
    <div className="review-list">
      <ul>
        {/* <li>
          <a
            href="https://www.trustpilot.com/review/brightdata.com"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img
              decoding="async"
              className="alignnone wp-image-123985 size-full no-lazy"
              src="/wp-content/uploads/2022/10/trustpilot_hp_v4.svg"
              alt=""
              width="61"
              height="33"
            />
          </a>
        </li> */}
        <li>
          <a
            href="https://www.g2.com/products/icereach/reviews"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img
              decoding="async"
              className="alignnone size-full wp-image-123977 no-lazy"
              src="/g2.svg"
              alt=""
              width="60"
              height="33"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.capterra.com/p/251470/Icereach/"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img
              decoding="async"
              className="alignnone size-full wp-image-123969 no-lazy"
              src="/capterra.svg"
              alt=""
              width="60"
              height="31"
            />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default ReviewList
